import React from 'react'
import PropTypes from 'prop-types'
import { StandardContentHeader } from './index.style'
import ScrollSection from '../ScrollSection'
import Container from '../Container'
import Grid from '../Grid'
import GridItem from '../GridItem'
import { Heading1 } from '../TextStyles'
import Spacer from '../Spacer'
import RichText from '../RichText'
import AnimateSplitText from '../animation/AnimateSplitText'

const StandardContent = ({ title, content }) => (
  <>
    <ScrollSection>
      <StandardContentHeader>
        <Container>
          <Grid>
            <GridItem tabletP={10} tabletPStart={2}>
              <Spacer size={[118, 272]} />

              <Heading1>
                <AnimateSplitText heading gradient>
                  FirstFit
                  <br />
                  {title}
                </AnimateSplitText>
              </Heading1>

              <Spacer size={[84, 172]} />
            </GridItem>

            <GridItem></GridItem>
          </Grid>
        </Container>
      </StandardContentHeader>
    </ScrollSection>

    <ScrollSection threshold={0}>
      <Container>
        <Spacer size={[50, 132]} />

        <Grid>
          <GridItem
            tabletP={10}
            tabletPStart={2}
            desk={8}
            deskStart={3}
            deskL={6}
            deskLStart={4}
          >
            <RichText content={content} paragraphSize="regular" />
          </GridItem>
        </Grid>

        <Spacer size={[64, 224]} />
      </Container>
    </ScrollSection>
  </>
)

StandardContent.propTypes = {
  title: PropTypes.string,
  content: PropTypes.object,
}

export default StandardContent
