import React from 'react'
import { graphql } from 'gatsby'
import Seo from '../components/Seo'
import Footer from '../components/Footer'
import StandardContent from '../components/StandardContent'

const StandardContentPage = ({ data }) => {
  const {
    seoTitle,
    seoDescription: { seoDescription },
    content,
  } = data.contentfulPrivacyPolicy

  return (
    <>
      <Seo title={seoTitle} description={seoDescription} />

      <StandardContent title={seoTitle} content={content} />

      <Footer />
    </>
  )
}

export const pageQuery = graphql`
  query pageContentPagesBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulPrivacyPolicy(slug: { eq: $slug }) {
      seoTitle
      seoDescription {
        seoDescription
      }
      content {
        raw
      }
    }
  }
`

export default StandardContentPage
